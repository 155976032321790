@import 'Variables.scss';

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/robotocondensed-regular-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-VariableFont_wght.ttf') format('ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat Light';
    src: url('../fonts/Montserrat-VariableFont_wght.ttf') format('ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Montserrat Thin';
    src: url('../fonts/Montserrat-VariableFont_wght.ttf') format('ttf');
    font-weight: 100;
}
  
  $Roboto: 'Roboto Condensed','Helvetica Neue',Helvetica,Arial,sans-serif;
  $Montserrat: 'Montserrat','Helvetica Neue',Helvetica,Arial,sans-serif;
  $MontserratLight: 'Montserrat Light','Helvetica Neue',Helvetica,Arial,sans-serif;
  $MontserratThin: 'Montserrat Thin','Helvetica Neue',Helvetica,Arial,sans-serif;

/* DEFAULTS */

.text-right {
    text-align: right;
}

.badge {
    margin-right: 5px;
}

button, .card {
    svg {
        width: 20px;
        vertical-align: middle;
        margin: 0px 5px 5px 0px;
    }
}

.k-button {
    background-color: #edeeee;

    &.close-top-left {
        position: absolute;
        top: 0px;
        right: 0px;
        margin: 3px 3px 0px 0px;
        padding: 3px 0px 0px 5px;
        text-align: center;
    }
}

/* HEADINGS */

h1, h2, h3, h4 {
    font-family: $Montserrat;
}

h1 {
    font-family: $MontserratLight;
}

/* LOADING SPINNER */

.loader-container {
    position: absolute;
    background-color: #FFF;
    border-radius: 50%;
    padding: 10px;
    top: 50%;
    left: 50%;
}

.loader {
    width: 48px;
    height: 48px;
    border: 3px solid $nav-bg;
    border-radius: 50%;
    display: block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    &.inline {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
    }
  } 
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #969595;   
  }

  .loader.inline::after {
    width: 28px;
    height: 28px;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

/* Icons */
svg.inline {
    width: 20px;
    vertical-align: middle;
    margin: 0px 5px 5px 0px;
}

/* LAYOUT */

.vcenter {
    display: flex;
    align-items: center;
}

.main.container {
    
}

footer {
    background-color: $footer-bg;
    color: $footer-text;
    font-family: $Montserrat;
    text-align: center;
    height: 150px;
    line-height: 150px;
    clear: both;

    a {
        color: $footer-text;
    }
}

// Home Page

.homepage {

    .white-bg {
        background-color: white;
    }

    .green-bg {
        background-color: $nav-bg;
    }

    .section-1 {
        height: 800px;
        background-color: $nav-bg;

        .leading-image-1 {
            height: 660px;
            background-color: white;
            background-image: url(../images/section-1-bg.png);
            background-position: center;
            background-position-y: -20px;
        }

        .main-box {
            width: 600px;
            margin: -360px auto 30px;
            padding: 50px 50px 50px 50px;

            .big-bold {
                font-size: 2.4em;
                font-weight: bold;
                line-height: 1.1em;
                color: $nav-bg;
            }
        }
    }

    .section-1::after {
        clear:both;
    }

    .section-2 {
        padding: 30px;
        background-color: $nav-bg;

        .main-box {
            min-height: 450px;
        }

        .image-container {
            height: 120px;
            width: 120px;
            border-radius: 60px;
            background-color: $nav-bg;
            margin: 10px auto 30px auto;
            text-align: center;

            img {
                height: 100px;
                margin-top: 30px;
                margin-left: -70px;
            }
        }

        .big-bold {
            font-size: 1.2em;
            font-weight: 500;
            line-height: 1.1em;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        .small-text {
            font-size: 0.9em;
            color: #808080;
        }
    }

    .section-3 {
        border-top: 1px solid white;
        background-color: $nav-bg;

        .big-bold {
            padding: 30px 130px 30px 30px;
            font-size: 3em;
            font-weight: bold;
            line-height: 1.1em;
            color: white;
        }

        .small-text {
            font-size: 1.2em;
            font-weight: 1em;
            line-height: 2em;
        }

        .main-box {
            margin-top: -560px;
            box-shadow: none;

            img.tags {
                height: 800px;
                background-color: lightgrey;
            }
        }

        .narrow {
            padding: 0px 60px 60px 60px;
        }

        .green-box {
            height: 400px;
            width: 400px;
            margin: 30px auto;
            padding: 30px;
            background-color: $nav-bg;
            border-radius: 50px;
            color: white;

            img.customised-mealplans {
                width: 100%;
            }
        }

        img.how-it-works {
            height: 400px;
            width: 400px;
            margin: 30px auto;
            background-color: lightgrey;
        }

        .button-container {
            margin-top: 30px;
        }
    }

    .section-4-header {
        margin: 30px 0px 0px 0px;
        text-align: center;

        .big-bold {
            font-size: 3em;
        }
    }

    .section-4 {
        padding: 30px 60px;
        background-image: url(../images/section-4-bg.jpg);
        background-position: left;
        background-size: cover;

        .main-box {
            border-radius: 30px;
            min-height: 500px;
            text-align: left;

            .big-bold {
                font-size: 1.2em;
                font-weight: 500;
            }

            li {
                margin-top: 20px;
                margin-left: -30px;
                list-style-type: none;
            }
        }
    }

    .section-5 {
        margin-top: 60px;
        .uppercase {
            text-transform: uppercase;
            font-size: 1.4em;
            margin-top: 120px;
        }

        .big-bold {
            font-size: 2.6em;
            line-height: 1.2em;
            margin-bottom: 60px;
        }

        .small-text {
            font-size: 1.2em;
        }
        
        .button-container {
            text-align: left;
        }
    }

    .section-6 {
        color: white;        
        background-image: url(../images/section-6-bg.jpg);
        background-position: center;
        background-size: cover;

        .row {
            margin: 0px;
            background-color:rgba(0, 0, 0, 0.4);
        }

        .header {
            margin-top: 120px;
        }
        
        .review {
            margin-bottom: 60px;
            padding: 0px 60px 60px 60px;
            font-size: 1.2em;

            .author {
                margin-top: 40px;
            }

            .circle-image {
                height: 120px;
                width: 120px;
                border-radius: 120px;
                border: 3px solid white;
                position: relative;
                float: right;
                margin-top: -90px;

                &.bec {
                    background-image: url(../images/section-6-bec.jpg);
                    background-size: cover;
                }

                &.courtney {
                    background-image: url(../images/section-6-courtney.jpg);
                    background-size: cover;
                }

                &.sara {
                    background-image: url(../images/section-6-sara.jpg);
                    background-size: cover;
                    background-position-y: -20px;
                }

                &.empty {
                    border: 0px;
                }
            }
        }

        .big-bold {
            font-size: 1.2em;
        }
    }

    .section-7 {
        margin-top: 60px;

        .membership-circle {
            height: 200px;
            width: 200px;
            background-color: $nav-bg;
            border-radius: 100px;
            vertical-align: middle;
            text-align: center;
            color: white;
            text-transform: uppercase;
            padding-top: 60px;
            float: right;

            .name {
                font-size: 0.6em;
            }

            .price {
                font-size: 1.6em;
            }

            .duration {
                font-size: 0.5em;
            }
        }

        .big-bold {
            font-size: 1.6em;
        }

        .small-text {
            font-size: 1.1em;
        }

        .price-green {
            font-size: 1.6em;
            font-weight: bold;
            color: $nav-bg;
        }

        .button-container Button {
            font-weight: bold;
        }

        .main-box {
            margin-top: 0px;

            @media only screen and (max-width: 768px) {
                margin-top: 40px;
            }
        }
    }

    .section-8 {
        margin-top: 90px;
        margin-bottom: 90px;

        .big-bold {
            font-size: 1.2em;
            font-weight: bold;
        }
    }

    .header {
        text-align: center;
        font-size: 2.6em;
        margin-bottom: 60px;
    }

    .main-box {
        position: relative;
        margin: 30px auto;
        padding: 30px;
        background-color: white;
        border-radius: 50px;
        box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.2);
        text-align: center;
        font-size: 1.1em;

        
    }

    .main-box::after {
        clear:both;
    }

    .button-container {
        text-align: center;

        button {
            margin: 0px auto;
            font-size: 1em;
            text-transform: uppercase;
            padding: 1em 3em;
            border-radius: 50px;
        }
    }
}

// Sign In
.sign-up-tile {
    margin-top: 100px;

    h4 {
        font-family: $MontserratLight;
    }

    .logo {
        width: 100%;
        margin-bottom: 20px;
    }

    .k-floating-label-container {
        width: 100%;
    }

    button {
        width: 100%;
        margin-bottom: 15px;
    }
}

.sign-in-tile {
    text-align: center;
    margin-top: 100px;
    border: 1px solid #e4e7eb;
    border-radius: 5px;
    padding: 20px;
    
    .k-floating-label-container {
        width: 100%;
    }

    button {
        width: 100%;
        margin-bottom: 15px;
    }
}



/* NAVIGATION */

.navbar {
    border-bottom: 1px solid $secondary;
    background-color: $nav-bg;
    font-family: $Montserrat;
    
    .navbar-brand {

        float: left;
        text-transform: uppercase;
        font-size: 2rem;
        font-family: $MontserratThin;

        img {
            filter: invert(100%);
            height: 64px;
            margin-top: -10px;
            margin-bottom: -10px;
        }
    }

    .not-signed-in {
        float:right;
        margin-top: 10px;

        .small-text {
            font-size: 0.9em;
            color: white;
            margin-right: 20px;
        }

        .k-button {
            text-transform: uppercase;
        }
        
    }
}

.navbar-nav {
    .nav-item {
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
        padding: 10px 15px;

        a.nav-link {    
            color: $nav-text;
        }

        .userMenu {

            button {
            border: none;
            border-radius: 0px;
            background-color: inherit;
            font-size: 12px;
            line-height: 20px;
            text-transform: uppercase;
            color: inherit 55%;
            }

            .dropdown-item {
                font-size: 12px;
            }
        }

        .userMenu.show, .userMenu:focus {
            box-shadow: none;
            button {
            border: inherit;
            background-color: inherit;
            box-shadow: none;
            }
        }

        svg {
            height: 1.6em;
            margin-right: 5px;
            vertical-align: middle;
        }
    }

}

/* FORM FIELDS */

.form-container {
    .k-button {
        svg {
            width: 20px;
        }
    }
}

.filter-container {
    margin: 15px 0px;

    .search-box {
        width: 100%;
    }
}

/* OVERVIEW and TUTORIALS */
img.signoff-image {
    width: 88px;
    object-fit: contain;
}

.overview-image-container {
    width: 30%;
    float: left;
    margin: 0px 40px 10px 0px;
    text-align: center;
    font-size: 0.9em;

    img {
        width: 100%;
        object-fit: contain;
    }
}

.tutorials-page {
    h3 {
        margin-top: 3em;
    }

    .youtube-links {
        svg {
            height: 2em;
            margin-right: 10px;
        }
    }
}

/* RECIPE LIST */

.recipe-filters {
    margin-top: 10px;
    margin-bottom: 10px;

    .k-floating-label-container {
        width: 100%;

        .k-multiselect {
            width: 100%;
        }
    }
    
    .btn-search {
        width: 100%;
        margin-top: 20px;
    }
}

.recipe-listview {
    height: 700px;
}


.recipe-card {
    display: inline-block;
    width: 32%;
    margin: 0px 1% 20px 0px;
    vertical-align: top;

    .card-img {
        height: 200px;
        object-fit: cover;
    }

    .card-img-placeholder {
        height: 200px;
        text-align: center;
        
        svg {
            margin-top: 10%;
            width: 100px;
            opacity: 0.2;
        }
    }

    .k-button-sm {
        margin: 0px;
        padding: 0px;
    }
}

/* RECIPE EDIT */

.ingredients-edit-grid {
    font-size: 0.8em;
}

.instructions-edit-grid {
    .k-grid-header {
        display: none;
    }    
}

.k-grid td.grid-row-commands-cell {
    text-align: right;
}

.image-selector-item {
    display: inline-block;
    width: 200px;

    img {
        width: 100%;
        object-fit: cover;
    }

    &.logo {
        width: 500px;

        img {
            height: 100px;
            object-fit: contain;
        }
    }

}

/* RECIPE VIEW */
.recipe-display {
    .recipe-main-image {
        height: 500px;
        width: 100%;
        object-fit: contain;
        border-radius: 5px;
    }

    .badge {
        margin-bottom: 10px;
        font-size: 1em;
    }

    .servings {
        .k-slider {
            width: 100%;
        }
    }

    .donut-chart {
        height: 300px;
    }
}

.ingredients-table, .instructions-table {
    width: 100%;

    td {
        padding: 10px 20px 10px 0px;
        border-bottom: 1px solid #DDD;

        &.step {
            text-align: center;
            vertical-align: top;
            font-weight: bold;
            width: 60px;
        }

        &.ingredient-category {
            padding: 16px 10px;
        }

        &.from-recipe {
            text-align: right;
        }

        &.optional-label {
            text-align: right;
            width: 60px;

            .badge {
                font-size: 0.8em;
                margin-bottom: 0px;
            }
        }
    }
}

.time-card {
    text-align: center;

    .metric-time {
        font-size: 3em;
        margin-top: 0px;
    }
    .metric-measure {
        font-size: 0.8em;
        line-height: 0.8em;
        margin-top: 0px;
    }

    .card-footer {
        text-align: center;
    }
}

.notes {
    .card-header {
        cursor: pointer;
    }
}


.nutrition-table {
    font-size: 0.9em;

    .row {
        border-bottom: 1px solid #DDD;

        .col-md-2 {
            padding: 10px 0px 10px 50px;
        }
        .col-md-2:first-of-type {
            padding-left: 10px;
        }

        .col-md-1 {
            padding: 10px 10px 10px 0px;
            text-align: right;
        }
    }
}

// Meal Plan

.mealplan-list {
    .created-totals {
        display: inline-block;
        margin-right: 20px;

        .mdi-info {
            height: 20px;
            vertical-align: middle;
        }
    }
}

.mealplan-container {
    min-width: 1296px;

    .k-grid {

        .k-master-row, .k-master-row:hover, .k-master-row.k-alt {
            background: transparent;
        }

        .k-grid-header {
            display: none;
        }

        td.mealplan-row-details {
            width: 170px;
            padding: 5px;
            margin: 0px;
            vertical-align: top;
        }

        td.mealplan-commands-cell {
            width: 0px;
            padding: 0px;

            .mealplan-commands {
                width: 160px;
                position: absolute;
                left: 0px;
                margin-top: -40px;
                margin-left: 5px;

                .k-button {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
        
        td.mealplan-cell-container {

            padding: 5px 0px 0px 5px;

            .mealplan-cell {
                
                min-height: 200px;
                width: 150px;
                max-width: 150px;
                padding: 10px;
                margin: 0px 5px 0px 0px;
                vertical-align: top;
                display: inline-block;
                border: 1px solid #dee2e6;

                .recipe-button {
                    position: relative;
                    height: 80px;
                    margin-bottom: 3px;
                    background-color: #DDD;
                    border: 1px solid #DDD;
                    background-size: cover;

                    &.leftover {
                        opacity: 0.6;
                    }

                    .recipe-label {
                        position: absolute;
                        bottom: 0px;
                        width: 130px;
                        height: 26px;
                        padding: 3px;
                        font-size: 0.8em;
                        background-color: rgba(255, 255, 255, 0.8);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .k-button-sm {
                    padding: 0px;
                }
            }

            .mealplan-row-uncreated {
                height: 200px;
                width: 100%;
                text-align: center;
                padding-top: 100px;
                font-size: 0.8em;
            }
        }
    }

    .macro-summaries-container {
        margin-top: 0px;
        font-size: 0.8em;

        .week-buttons {
            width: 178px;
            display: inline-block;
        }
        
        .macro-summaries {

            .day {
                border: 1px solid #e4e7eb;
                display: inline-block;
                width: 150px;
                margin-right: 5px;

                .row {
                    margin: 0px 5px;
                    padding: 2px 0px;
                }

                .row:nth-child(even){
                    background-color: #edeeee;
                }
            }
        }
    }

    .day-of-week-container {
        margin-top: 20px;
        margin-left: 178px;

        input {
            display: inline-block;
            width: 150px;
            margin-right: 5px;
        }
    }
}

.mealplan-export-container {

    .recipe-image {
        height: 50px;
        width: 100px;
        object-fit: cover;
    }
}

img.tutorial-image {
    width: 100%;
    object-fit: contain;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin: 10px 0px;
}

// TODO: remove this chunk
.recipe-popup-wrapper {
    .recipe-popup {
        width: 300px;
        
        .recipe-popup-content {

            .card-header {
                padding-right: 40px;
            }

            .servings-label {
                padding: 3px 5px;
            }

            .k-checkbox-label {
                padding-left: 5px;
                vertical-align: middle;
            }

            .k-button-group {
                float: right;
            }

            .delete-button {
                
            }        
        }
    }
}

.add-to-mealplan-popup {
    
    table {
        .name-wrapper {
            padding: 10px;
        }
        .checkbox-wrapper {
            padding: 5px 2px;

            .k-checkbox {
                padding: 15px;
            }

            .has-items {
                .k-checkbox {
                    border-color: #6c757d;
                    background-color: #6c757d;
                }
            }
        }
    }

    .k-button-group {
        float: right;
    }
}

@media only screen and (max-width: 768px) {
    
    .hide-on-mobile {
        display: none;
    }

    .navbar .container {
        text-align: center;

        .navbar-brand {
            float:none;
        }
    }

    footer {
        .container {
            margin-top: 20px;
            font-size: 0.9em;
            line-height: 2em;
        }
    }

    .homepage {

        text-align: center;

        .section-1 {
            .leading-image-1 {
                background-size: 200%;
            }

            .main-box {
                width: 100%;
                margin-top: -400px;

                .big-bold {
                    font-size: 1.2em;
                }
            }
        }

        .section-3 {
            text-align: center;

            .big-bold {
                padding: 30px 30px 60px 30px;
                font-size: 1.6em;
                text-align: center;
            }

            .main-box {
                margin-top: -40px;

                img.tags {
                    height: 100%;  
                    width: 100%;
                    height: none;
                }
            }

            .green-box {
                width: 300px;
                height: 300px;
            }

            img.how-it-works {
                width: 100%;
                height: inherit;
            }
        }

        .section-4-header {
            font-size: 0.7em;
            margin-top: 60px;
        }

        .section-4 {
            padding: 20px;
        }

        .section-5 {

            .uppercase {
                margin-top: 20px;
                font-size: 1.2em;
            }

            .feature-image {
                margin-top: 20px;
                margin-left: -100px;
            }
        }

        .section-6 {
            .header {
                margin-top: 30px;
                font-size: 2.2em;
            }
            .review {
                padding: 0px 36px 20px 36px;

                .circle-image {
                    float:none;
                    margin: 20px auto 20px auto;
                }
            }
        }

        .section-7 {
            text-align: center;

            .membership-circle {
                float:none;
                margin: 0px auto 20px auto;
                font-size: 1.2em;
            }
        }
    }
}

// PDF Export
.k-pdf-export {

    h1, h2, h3, h4 {
        font-family: $Roboto;
    }

    h1 {
        font-size: 3rem;
    }

    .no-print {
        display: none;
    }

    .k-button-group {
        display: none;
    }

    .notes {
        .collapse {
            display: none;

            &.show {
                display: block;
            }
        }
    }

    .pdf-header {
        height: 100px;
        padding: 30px;
        background-color: #6c757d;
        color: #FFF;

        .logo-container {
            text-align: center;
        }
    }
}
